<template>
  <div class="app-container">
    <h2>{{ this.$i18n.t('route.menu_revenue_report_realtime') }}</h2>
    <el-table :data="loadedData.restaurantLines" :row-class-name="tableRowClassName" :cell-class-name="cellClassName">
      <el-table-column prop="position" :label="$t('revenueReport.position')" width="100px" sortable />
      <el-table-column prop="restaurantName" :label="$t('common.restaurantName')" width="300px" sortable />
      <el-table-column
        prop="totalRevenueIncludingVAT"
        :label="$t('revenueReport.totalRevenueIncludingVAT')"
        width="180px"
        sortable
        class-name="amount"
      >
        <template #default="scope">
          <div>{{ localizedAmount(scope.row.revenueIncludingVat) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="totalRevenueExcludingVAT"
        :label="$t('revenueReport.totalRevenueExcludingVAT')"
        width="180px"
        sortable
        class-name="amount"
      >
        <template #default="scope">
          <div>{{ localizedAmount(scope.row.revenueExcludingVat) }}</div>
        </template>
      </el-table-column>
      <el-table-column />
    </el-table>

    <div class="lastUpdated">{{ this.$i18n.t('revenueReport.lastUpdated') }} {{ localizedTimeStamp }}</div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';

export default {
  name: 'Realtime',
  data() {
    return {
      reloadInterval: 120,
      timeoutId: undefined,
      loading: false,
      loadedData: {
        reportTime: undefined,
        restaurantLines: []
      }
    };
  },
  computed: {
    restaurantId() {
      return this.$store.getters.restaurantId;
    },
    localizedTimeStamp() {
      if (!this.loadedData.reportTime) {
        return '';
      }
      console.log(this.loadedData.reportTime, typeof this.loadedData.reportTime);
      const date = new Date(this.loadedData.reportTime);
      const languageCode = this.$store.getters.language || 'nl';
      return date.toLocaleTimeString(languageCode);
    }
  },
  created() {
    this.loadAsync();
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    async loadAsync() {
      return new Promise(() => {
        const loadingInstance = Loading.service({
          lock: true,
          background: 'rgba(255, 255, 255, 0.5)'
        });
        this.$store
          .dispatch('restaurant/getRevenueReportRealtime')
          .then(data => {
            this.loadedData = data;
          })
          .finally(() => {
            loadingInstance.close();
          });
        this.timeoutId = setTimeout(this.loadAsync, this.reloadInterval * 1000);
      });
    },
    tableRowClassName({ row }) {
      if (row.restaurantID === this.restaurantId) {
        return 'current-restaurant';
      }
      return '';
    },
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 2 || columnIndex === 3) {
        return 'amount';
      }
      return '';
    },
    localizedAmount(amount) {
      if (!amount) {
        amount = 0;
      }
      const amountNumber = Number(amount);
      const languageCode = this.$store.getters.language || 'nl';
      return amountNumber.toLocaleString(languageCode, { style: 'currency', currency: 'EUR' });
    }
  }
};
</script>

<style>
.el-table__row.current-restaurant {
  color: #e4061f;
  font-weight: bold;
}

.el-table th.amount,
.el-table td.amount {
  text-align: right;
}

.lastUpdated {
  font-style: italic;
  font-size: smaller;
  margin-top: 20px;
}
</style>
